import React, { useState } from "react";
import { SIGN_UP_PROCESS_STEP } from "../../commons/signup.constants";
import NewSignup from "../signup/components/sign-up-container";

const ReferralPage = ({ staffCode = "" }) => {
  const [signupProcess, setSignupProcess] = useState(
    SIGN_UP_PROCESS_STEP.COLLECT_BASIC_INFO
  );
  const [params, setParams] = useState({
    codeId: "",
    email: "",
    password: "",
    firstName: "",
    lastName: " ",
    phoneNumber: "",
    referralBy: "",
    staffCode,
  });

  const handleSetSignupProcess = (value) => {
    setSignupProcess(value);
  };

  const handleSetParams = (value) => {
    setParams(value);
  };

  return (
    <NewSignup
      signupProcess={signupProcess}
      setSignupProcess={handleSetSignupProcess}
      params={params}
      setParams={handleSetParams}
    />
  );
};

export default ReferralPage;
